// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:1b09dbca-e74a-4585-bc31-4427c370d908",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_eABxB4QVN",
    "aws_user_pools_web_client_id": "58qt16gibkif212o7e9dha9bti",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://lwm3ie4bxbcnnpfiv6yrruq3ni.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "dreamit-prefect-csa092eb95d236c456bbfd9dd93f0490b66-csa",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
