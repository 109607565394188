import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  { path: '', redirectTo: 'get-started', pathMatch: 'full' },
  { path: 'get-started', loadChildren: './get-started/get-started.module#GetStartedPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'complete-registration', loadChildren: './complete-registration/complete-registration.module#CompleteRegistrationPageModule', canActivate: [AuthGuard] },
  { path: 'enter-password', loadChildren: './enter-password/enter-password.module#EnterPasswordPageModule' },
  { path: 'verify-email', loadChildren: './verify-email/verify-email.module#VerifyEmailPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule', canActivate: [AuthGuard] },
  { path: 're-enter', loadChildren: './re-enter/re-enter.module#ReEnterPageModule' },
  { path: 're-verify', loadChildren: './re-verify/re-verify.module#ReVerifyPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
